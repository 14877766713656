<template>
    <li
        class="order-list-modal-item"
        @click="$emit('select-item')"
    >
        <span class="item-content">
            <slot />
        </span>
        <span class="arrow-icon-container">
            <ArrowRight />
        </span>
    </li>
</template>

<script>
import ArrowRight from '@/assets/images/new-icons/arrow-right-icon.svg';

export default {
    components: {
        ArrowRight
    }
}
</script>

<style lang="scss">
.order-list-modal-item {
    &:hover {
        .arrow-icon-container {
            svg path {
                fill: #974900 !important;
            }
        }
    }
    .arrow-icon-container {
        width: 15px;
        height: 24px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .arrow-icon-container {
        width: 10px !important;
        height: 16px !important;
    }

}
</style>

<style lang="scss" scoped>
li {
    height: 88px;

    display: flex;
    align-items: center;

    padding: 0 29px;
    border-bottom: 1px solid #CFC4BE;
    user-select: none;
    gap: 0 20.5px;
    cursor: pointer;

    &:hover {
        background: #FFEDE2;
        border-bottom: 1px solid #974900;

        .item-content {
            color: #974900;
        }

        .arrow-icon-container {
            svg {
                path {
                    fill: #974900 !important;
                }
            }
        }
    }

    &:active {
        background: #FFDBC4;
    }

    .item-content {
        font-size: 14px;
        line-height: 20px;
        color: #4C4541;
        flex-grow: 1;

        word-break: break-all;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

@media (max-width: 768px) and (min-width: 480px) {
    li {
        height: 94px !important;
        .item-content {
            font-size: 22px !important;
            line-height: 30px !important;
        }
    }
}

@media (max-width: 480px) {
    li {
        height: 80px !important;
        padding: 32px 16px !important;

        .item-content {
            font-size: 12px !important;
            line-height: 16px !important;
        }
    }
}
</style>
