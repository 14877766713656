<template>
    <header class="header-container">
        <h1>{{ $t(`ProductionProgress.OrderDetails2`) }}</h1>

        <button @click="close" class="close-button-order-list-modal">
            <CloseIcon />
        </button>
    </header>
</template>

<script>
import CloseIcon from '@/assets/images/new-icons/close-dark-icon.svg';

export default {
    components: {
        CloseIcon,
    },
    props: {
        close: {
            type: Function,
            default: () => {}
        }
    }
}
</script>


<style lang="scss">
.header-container {
    .close-button-order-list-modal {
        svg {
            height: 16px;
            width: 16px;

            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media (max-width: 768px) and (min-width: 480px) {
    .header-container {
        .close-button-order-list-modal {
            svg {
                height: 24px !important;
                width: 24px !important;
            }
        }
    }
}

</style>

<style lang="scss" scoped>
.header-container {
    display: flex;
    align-items: center;
    padding: 16px 29px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
    user-select: none;
    z-index: 9999;
    gap: 0 10px;

    h1 {
        flex-grow: 1;
        font-weight: 600;
        font-size: 26px;
        line-height: 36px;
        color: #4C4541;
        margin: 0;
    }

    button {
        padding: 0;
        border: none;
        background: none;
    }
}

@media (max-width: 768px) and (min-width: 480px) {
    .header-container {
        h1 {
            font-size: 36px !important;
            line-height: 50px !important;
        }
    }
}

@media (max-width: 480px) {
    .header-container {
        padding: 16px !important;

        h1 {
            font-size: 18px !important;
            line-height: 26px !important;
        }
    }
}
</style>
